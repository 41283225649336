<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>

          <!-- <v-col
            md="6"
            cols="12"
          >
          <label for="">Company</label>
            <v-chip
                class="ma-2"
                color="green"
                text-color="white"
              >
                {{account.company_name}}
              </v-chip>
          </v-col> -->
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="email"
              label="Email"
              dense
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="name"
              label="Name"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="6"
            cols="12"
          >
            <v-select
                :items="roles"
                v-model="roles_selected"
                multiple
                label="Select role"
                item-text="name"
                item-value="id"
                chips
                dense
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click.prevent="createUser"
            >
              Save
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click.prevent="cancel"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '@axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      email: '',
      name: '',
      password: '',
      client: '',
      roles: [],
      roles_selected: [],
    }
  },
  computed: {
    ...mapGetters({
      hasUserRole: 'auth/hasUserRole',
      hasUserPermission: 'auth/hasUserPermission',
    }),
  },
  methods: {
    async getRoles() {
      await axios
        .get('/role')
        .then(response => {
          this.roles = response.data
        })
        .catch(() => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to get roles!')
          console.log('error :>> ', error.response)
        })
    },
    createUser() {
      axios
        .post('/user/create', {
          email: this.email,
          name: this.name,
          password: this.password,
          client_id: this.client,
        })
        .then(response => {
          if (response.data.error) {
            this.$alertify.error(response.data.error)
          } else {
            this.updateRoles(response.data.id)
          }
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.error
        })
    },
    updateRoles(user_id) {
      axios
        .post('/user/create-rol', { user: user_id, roles: this.roles_selected })
        .then(response => {
          this.email = ''
          this.name = ''
          this.roles_selected = []
          this.client = ''
          this.password = ''
          this.$alertify.success('User created')
          this.$router.push('/users')
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to update! role')
          console.log('error :>> ', error.response)
        })
    },
    cancel() {
      this.$router.push('/users')
    },
  },
  async mounted() {
    if (this.hasUserRole('Super Admin') || this.hasUserRole('Client Owner')) {
      await this.getRoles()
    } else {
      this.loading = false
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },
}
</script>
